<template>
  <div>
    <b-row class="form-box">
      <b-col md="12">
        <b-nav tabs class="form-section">
          <b-nav-item disabled>Purchaser & Last Modification</b-nav-item>
        </b-nav>
      </b-col>
      <b-col
        :md="item.md || 6"
        v-for="(item, index) in initData"
        :key="index"
        :class="{
          'my-1': item.type != null && item.text != null
        }"
      >
        <b-form-group
          v-if="item.type != null"
          :label="item.text"
          :id="item.id"
          :label-for="'form-' + index"
          class="search-flex"
          v-bind:class="[{ required: item.required }, item.class]"
          label-cols-sm="4"
          label-cols-lg="3"
        >
          <!-- input -->
          <template v-if="item.type == 'input'">
            <b-input-group :class="{ no_r: !item.icon }">
              <template v-slot:append>
                <b-input-group-text v-if="item.icon || item.icon_text">
                  <b-icon v-if="item.icon" :icon="item.icon"></b-icon>
                  {{ item.icon_text }}
                </b-input-group-text>
              </template>

              <b-form-input
                v-model="item.model"
                :type="item.input_type || 'text'"
                :placeholder="
                  item.disabled
                    ? ''
                    : item.placeholder || $t('page.please_choose_input')
                "
                :disabled="item.disabled"
              ></b-form-input>
            </b-input-group>
          </template>
          <!-- 下拉框 -->
          <template v-else-if="item.type == 'select'">
            <SelectLoading
              v-if="item.options.length == 0"
              :size="0.7"
            ></SelectLoading>
            <sv-select
              v-model="item.model"
              :disabled="item.options == 0 || item.disabled"
              :placeholder="
                item.disabled
                  ? ''
                  : item.placeholder || $t('page.please_choose')
              "
              :options="item.options"
              :reduce="options => options.value"
              :multiple="item.multiple"
              :selectable="options => !options.selectable"
              label="label"
            >
              <template slot="option" slot-scope="option" v-if="option.index">
                <div class="d-center" :class="'level_' + option.index">
                  <span v-if="option.index == 2">--</span>
                  {{ option.label }}
                </div>
              </template>
            </sv-select>
          </template>
          <!-- 时间组件-->
          <template v-else-if="item.type == 'date'">
            <b-input-group>
              <template v-slot:append>
                <div
                  v-if="!item.disabled"
                  class="datepicker-x"
                  @click="initialTime(item, 'model')"
                >
                  <b-icon-x font-scale="1.45"></b-icon-x>
                </div>
                <b-input-group-text>
                  <b-icon-calendar2-date></b-icon-calendar2-date>
                </b-input-group-text>
              </template>
              <b-form-datepicker
                v-model="item.model"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric'
                }"
                :hide-header="true"
                :placeholder="item.disabled ? '' : item.placeholder"
                :disabled="item.disabled"
                locale="de"
              ></b-form-datepicker>
            </b-input-group>
          </template>
          <template v-if="item.type == 'textarea'">
            <b-form-textarea
              :id="item.id"
              :rows="item.rows"
              :placeholder="
                item.disabled
                  ? ''
                  : item.placeholder || $t('page.please_choose_textarea')
              "
              :disabled="item.disabled"
              v-model="item.model"
            ></b-form-textarea>
          </template>
        </b-form-group>
      </b-col>
    </b-row>
  </div>

  <!-- <Others></Others> -->
</template>

<script>
// import ReferenceLink from '@/components/ReferenceLink/Index';
import SelectLoading from '@/components/Loading/Index';
// import MerchantPartner from '@/views/content/Detail/Information/MerchantPartner';
// import Others from '@/views/content/Detail/Information/Others';
export default {
  name: 'PurchaseoOverviewPurchaser',

  data() {
    return {};
  },
  props: {
    initData: Object,
    options: Object
  },
  inheritAttrs: false,
  components: {
    // ReferenceLink
    SelectLoading
    // MerchantPartner
    // Others
  },
  methods: {
    initialTime(obj, name) {
      console.log(obj, name);
      obj[name] = null;
    }
  },

  computed: {},
  mounted() {}
};
</script>
<style scoped>
.image-box .bv-no-focus-ring {
  display: flex;
  justify-content: center;
  align-items: center;
}
.image {
  max-width: 100%;
  max-height: 170px;
}
</style>
